const generateHTML = eleDOM => {
  const markUp = `
    <div class='form-group'>
     <textarea id='content' placeholder='보충질문' class='form__input' rows='1' name='content'></textarea>
    </div>
    <div class='form-group'>
      <div id='container' class='add__canvas'>
        <canvas id='c' ></canvas>
      </div>
      <input type='file' id='image' name='picture' accept='.jpeg, .jpg, .png'/>
    </div>
  `;
  eleDOM.insertAdjacentHTML("afterbegin", markUp);
}

const LabeledRect = fabric.util.createClass(fabric.Rect, {

  type: 'labeledRect',
  initialize: function(options) {
    options || (options = { });
    this.callSuper('initialize', options);
    this.set('label', options.label || '');
  },
  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      label: this.get('label')
    });
  },

  _render: function(ctx) {
    this.callSuper('_render', ctx);

    ctx.font = '20px Helvetica';
    ctx.fillStyle = '#333';
    //ctx.fillText(this.label, -this.width/2, -this.height/2 + 20);
    ctx.fillText(this.label, -5, 5);
  }
});

const setFixed = function() {
  this.disabled = false; // one crop
  this.canvas.isDrawingMode = false;
  this.imgInstance.set({
    selectable: false,
    hasBorders: false,
    hasControls: false,
    hasRotatingPoint: false,
    evented: false,
  });
  this.canvas.renderAll();
  this.canvas.hoverCursor = "pointer";
  // this.canvas.hoverCursor = "crosshair";  
} 

const maskArea = function() {
  let num = this.canvas.getObjects().length;
  let labeledRect = new LabeledRect({
    width: 100,
    height: 30,
    left: 5,
    top: 5,
    rx: 5,
    ry: 5,
    label: num,
    fill: '#aaf'
  });
  this.canvas.add(labeledRect);
  this.canvas.setActiveObject(labeledRect);
  this.canvas.bringToFront(labeledRect).renderAll();
}

const imageCrop = function (canvas, button) {
  this.imgInstance.cloneAsImage(
    function (clone) {
      //all remove except clone
      const objs = canvas.getObjects();
      
      // this.canvas.setWidth(drawWidth);
      // this.canvas.setHeight(drawHeight);
      canvas.add(clone);
      canvas.centerObject(clone);
      canvas.setActiveObject(clone);
      
      objs.forEach(el => canvas.remove(el));
      canvas.renderAll();
    },
    {
      left: this.cropRect.left - this.imgInstance.left,
      top: this.cropRect.top - this.imgInstance.top,
      width: this.cropRect.width,
      height: this.cropRect.height,
    }
  );

  this.disabled = true;
  this.rectangle.visible = false;
  button.style.display = "none";
  // canvas.renderAll();
};

const readyButtons = function (divAfter, cbSetFixed, cbMaskArea) {

  let divBtns = document.createElement("div");
  divBtns.classList.add('toolbox');
  divAfter.insertAdjacentElement("afterbegin", divBtns);

  let btnSetFixed = document.createElement("button");
  btnSetFixed.textContent = "📌";
  // btnSetFixed.style.display = "none";
  divBtns.insertAdjacentElement("beforeend", btnSetFixed);

  btnSetFixed.addEventListener("click", function (e) {
    e.preventDefault();
    e.target.style.display = "none";
    cbSetFixed();
  });

  let btnMask = document.createElement("button");
  btnMask.textContent = "🖌";
  // btnMask.style.display = "none";
  divBtns.insertAdjacentElement("beforeend", btnMask);
  btnMask.addEventListener("click", function (e) {
    e.preventDefault();
    cbMaskArea();
  });

};

QuestionImg.prototype.readURL = function () {

  let drawWidth = this.drawArea.offsetWidth;
  let drawHeight = this.drawArea.offsetHeight;
  
  
  if (this.fileInput.files && this.fileInput.files[0]) {
    const reader = new FileReader();
    // all object delete
    this.disabled = false;
    this.canvas.clear();
    
    reader.onloadend = function(e) {
      try {
        const imgObj = new Image();
        // imgObj.src = reader.result;
                
        let that=this;
        //imgObj.src = e.target.result;
        
        imgObj.onload = function() {
          let imgX = imgObj.width;
          let imgY = imgObj.height;
          let sx = drawWidth / imgX;
          let sy = drawHeight / imgY;
          
					// console.log(`img x = ${imgX} sx : ${sx} img y = ${imgY} sy : ${sy}`);
          //현재 창에 전체가 다 보여질수 있도록
          let scale = sx > sy ? sy : sx;
          try {
            let imgInstance = new fabric.Image(imgObj);
            imgInstance.scale(scale * that.viewScale);
            imgInstance.hasRotatingPoint = false;
            
            that.canvas.isDrawingMode = false;
            
            //imgInstance.selectable = false;
            that.canvas.setWidth(imgInstance.width > that.maxW ? that.maxW : drawWidth);
            that.canvas.setHeight(imgInstance.height > that.maxH ? that.maxH : drawHeight);
            that.canvas.add(
              imgInstance.set({
                left: 10,
                top: 20,
              })
              );
              that.canvas.setActiveObject(imgInstance);
              
              //that.canvas.centerObject(imgInstance);
              that.canvas.renderAll();
              that.imgInstance = imgInstance;
              
            } catch (e) {
              cards5dap.showOn(e);
            }
          }.bind(that);
          imgObj.src = e.target.result;
        
        } catch (e) {
          cards5dap.showOn(e);
        }
      
    }.bind(this);
        
    reader.readAsDataURL(this.fileInput.files[0]);

    //crop , mask 가 가능하게 
    this.disabled = true;
    this.canvas.isDrawingMode = false;
    // btnSetCrop.style.display = "inline-block";
    // btnMask.style.display = "inline-block";
  }
};

QuestionImg.prototype.dataURL2Blob = function() {
  const group = new fabric.Group(this.canvas.getObjects());
  
  if (!group.width || !group.height ) return null;
  const dataURL = this.canvas.toDataURL({
    left: group.left,
    top: group.top,
    width: group.width,
    height: group.height,
    format: "jpeg",
    quality: 0.5,
  });

  let arr = dataURL.split(","),
  mime = arr[0].match(/:(.*?);/)[1],
  bstr = atob(arr[1]),
  n = bstr.length,
  u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });

}

const rectStart= function (ev) {
  let drawWidth = this.drawArea.offsetWidth;
  let drawHeight = this.drawArea.offsetHeight;
  //delete crop button
  if (this.cropBtn) this.cropBtn.style.display = 'none';

  //delete all rectagle
  this.canvas.getObjects("rect").forEach(el => this.canvas.remove(el));
  // btnCrop.style.display = "none";
  this.canvas.hoverCursor = "crosshair";

  const mouse = this.canvas.getPointer(ev);
  //dspLog(JSON.stringify(event));
  let origX = mouse.x;
  let origY = mouse.y;

  this.rectangle = new fabric.Rect({
    //fill: 'rgba(255, 255, 255, 0.4)',
    fill: "rgba(0, 25, 255, 0.3)",
    stroke: "rgba(255,0,0,0.5)",
    strokeDashArray: [2, 2],
    //visible: false,
    selectable: false,
    width: 0,
    height: 0,
    left: origX,
    top: origY,
    originX: "left",
    originY: "top",
  });

  this.canvas.add(this.rectangle);
  this.cropRect.origX = this.cropRect.left = origX;
  this.cropRect.origY = this.cropRect.top = origY;

  this.mouseDown = ev;
  
}

const rectDrag = function (event) {

  let pointer = this.canvas.getPointer(event);

  let origX = this.rectangle.get('left');
  let origY = this.rectangle.get('top');
  if (origX > pointer.x) {
    this.rectangle.set({ left: Math.abs(pointer.x) });
    this.cropRect.left = pointer.x;
  }
  if (origY > pointer.y) {
    this.rectangle.set({ top: Math.abs(pointer.y) });
    this.cropRect.top = pointer.y;
  }

  this.rectangle.set({ width: Math.abs(origX - pointer.x) });
  this.rectangle.set({ height: Math.abs(origY - pointer.y) });
  this.rectangle.setCoords();
  this.rectangle.visible = true;
  this.canvas.renderAll();
  this.canvas.hoverCursor = "crosshair";

  this.cropRect.width = this.rectangle.width;
  this.cropRect.height = this.rectangle.height;
}


fabric.Canvas.prototype.getAbsoluteCoords = function (object) {
  return {
    left: object.left + this._offset.left,
    top: object.top + this._offset.top,
  };
};

const attachBtn = function(canvas, obj,cbImgCrop) {
  let btnWidth = 20, btnHeight = 16;
  let absCoords = canvas.getAbsoluteCoords(obj);

  let box = document.getElementById('container');
  let btnCrop = document.createElement('button');
	btnCrop.textContent ='✂';
  btnCrop.style.position = 'absolute';
  btnCrop.style.left = (absCoords.left + obj.width - btnWidth / 2) + 'px';
  btnCrop.style.top = (absCoords.top + obj.height - btnHeight / 2) + 'px';
  btnCrop.style.display = "inline-block";
  box.insertAdjacentElement('beforeend', btnCrop);

  btnCrop.addEventListener('click', ev => {
    ev.preventDefault();
    ev.target.style.display = "none";
    cbImgCrop(canvas, ev.target);
  });

  return btnCrop;
}

const rectEnd = function(event, cbImgCrop) {

  let pointer = this.canvas.getPointer(event);

  // console.log(`mouse up pointer.x  ${pointer.x} pointer.y ${pointer.y}`);

  this.rectangle.visible = true;
  //canvas.bringToFront(rectangle).renderAll();

  this.cropBtn = attachBtn(this.canvas, this.rectangle, cbImgCrop);
  this.canvas.hoverCursor = 'pointer';

  this.mouseDown = null;
}

QuestionImg.prototype.prepareCanvas = function() {
  generateHTML(this.drawArea);
  
  this.toolbox = this.drawArea.querySelector('.add__canvas');
  this.canvas = new fabric.Canvas('c');
  //간단한 필기가 불가능하도록
  this.canvas.isDrawingMode = false;
  //이미지가 삽입되면 고정되지 않았으면 필기모드가 해제된다.
  
  this.fileInput = document.getElementById('image');
  
  //crop button handler 
  const btnCrop = setFixed.bind(this);
  //mask button handler
  const cbMaskArea = maskArea.bind(this);
  
  this.fileInput.addEventListener("change", () => {
    // let cbFn = this.loadImage();
    this.readURL();
    readyButtons(this.toolbox, btnCrop, cbMaskArea);
    //이미지 고정은 cropCut 버튼을 사용한다.
  });

  if (!this.pasteLister) {
    window.addEventListener("paste", e => {
      // only image processing
      if ((e.clipboardData || window.clipboardData).getData("text")) return;
      this.pasteLister = true;
      const copies = e.clipboardData.files;
      if (copies.length) {
        this.fileInput.files = copies;
        this.readURL();
        readyButtons(this.toolbox, btnCrop, cbMaskArea);
      } else {
        alert('no image copied!');
      }
    });
  }

  //event for crop, mask
  const cbMouseDown = rectStart.bind(this);
  const cbMouseDrag = rectDrag.bind(this);
  const cbMouseUp = rectEnd.bind(this);

  //imageCrop 
  const cbImgCrop = imageCrop.bind(this);

  this.canvas.on({
    "mouse:down": function (event) {
      if (!this.disabled) cbMouseDown(event);
    }.bind(this),

    "mouse:move": function (event) {
      if (this.mouseDown && !this.disabled) cbMouseDrag(event);
    }.bind(this),

    "mouse:up": function (event) {
      if (this.mouseDown && !this.disabled) cbMouseUp(event, cbImgCrop);
    }.bind(this),

    "touch:drag": function (ev) {
      if (this.mouseDown && !this.disabled) cbMouseDrag(ev);
    },

    // "path:created": function () {
    //   console.log("free draw ");
    // },
  });
}

export function QuestionImg(eleParent) {
  this.drawArea = eleParent;
  this.disabled = true;
  this.mouseDown = null;
  this.rectangle = null;
  this.cropRect = {
    origX: 0,
    origY: 0,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };
  this.viewScale = 0.9;
  this.maxW = 500;
  this.maxH = 500;
  this.cropBtn = null;
  this.pasteLister = null;
}
