export const drawLatex = (inputId, dispId) => {
  const latexInput = document.getElementById(inputId);
  const dispEq = document.getElementById(dispId);

  if (!latexInput || !dispEq) {
    console.log("check input, display area");
    return;
  }
  const codecogs = "http://latex.codecogs.com/png.latex";
  dispEq.style.cssText = "white-space: pre-wrap;";

  latexInput.addEventListener("keyup", e => {
    dispEq.textContent = "";
    let regexp = /(<eq>.*\s*.*<\/eq>)/g;
    let eqs = [...e.target.value.matchAll(regexp)];

    let latexP = latexInput.getElementsByClassName("latexP");
    if (latexP.length > 0) latexP[0].parentNode.remove();
    if (eqs.length > 0) {
      let imgEq = document.createElement("p");
      imgEq.classList.add("latexP");
      imgEq.innerHTML = e.target.value;
      dispEq.appendChild(imgEq);

      let eqqs = imgEq.getElementsByTagName("eq");
      // console.log(eqqs);
      if (eqqs)
        [...eqqs].map(el => {
          let eqText = el.innerHTML.trim();
          el.innerHTML = `<img class='eq' src='${codecogs}?${eqText}' />`;
        });
    }
  });
};

export const dispLatex = dispEqClass => {
  const dspLatex = document.getElementsByClassName(dispEqClass);
  const codecogs = "http://latex.codecogs.com/png.latex?\\dpi{150}";
  if (dspLatex)
    [...dspLatex].map(cls => {
      const eqqs = cls.getElementsByTagName("eq");
      if (eqqs.length > 0) {
        for (let j = 0; j < eqqs.length; j++) {
          let texEq = eqqs[j].innerHTML.trim();
          eqqs[j].innerHTML = '<img class="eq" src="' + codecogs + texEq + '"/>';
        }
      }
    });
};
