import { sendSignUp, sendLogin, sendLogout, updateData } from "./requests";
import { addQuestion , testQuestion } from "./question";
import { env } from './config';

// const login = document.querySelector("#frmLogin");
const logout = document.querySelector("a.nav__el--logout");
const profile = document.querySelector("form.form-user-data");
const password = document.querySelector("form.form-user-password");
const catalogue = document.querySelector("form.form__catalogue");

//add question
const addQarea = document.querySelector("form#addForm");

if (profile) {
  const send = profile.querySelector("#profile.btn");
  const photo = profile.querySelector("#photo");
  const update = new FormData();
  const sendTxt = send.textContent;
  send.addEventListener("click", async e => {
    send.textContent = "..Sending";
    // console.log(typeof update, update);
    e.preventDefault();
    console.log(update);
    await updateData(update, "user");
    send.textContent = sendTxt;
  });
  profile.dispatchEvent(new Event("change"));
  profile.addEventListener("change", e => {
    console.log(e.target);
    if (e.target.name === "photo") {
      console.log("equal to");
      const beginPos = photo.value.lastIndexOf("\\");
      const imgLabel = photo.value;
      const imgLocal = photo.files[0];
      profile.querySelector("img.form__user-photo").src = URL.createObjectURL(imgLocal);
      profile.querySelector("label.photo-text").textContent = imgLabel.slice(beginPos + 1);
      update.set(e.target.name, e.target.files[0]);
    } else {
      // update[e.target.name] = e.target.value;
      update.set(e.target.name, e.target.value);
    }
  });
}

if (catalogue) {
  const send = catalogue.querySelector("#catalogue.btn");
  const update = new FormData();
  const sendTxt = send.textContent;
  send.addEventListener("click", async e => {
    e.preventDefault();
    send.textContent = "..Sending";
    // console.log(typeof update, update);
    if (!update.has('share')) update.set('share', 1);
    console.log(update);
    await updateData(update, "newCatalogue");
    send.textContent = sendTxt;
  });
  catalogue.dispatchEvent(new Event("change"));
  catalogue.addEventListener("change", e => {
    // console.log(e.target.name, e.target.value);
    let newValue;
    if (e.target.type === "checkbox") {
      newValue = e.target.checked ? 1 : 0;
    } else {
      newValue = e.target.value;
    }
    update.set(e.target.name, newValue);
  });
}

if (password) {
  const send = password.querySelector("#password.btn");
  const update = {};
  const sendTxt = send.textContent;
  send.addEventListener("click", async e => {
    e.preventDefault();
    send.textContent = "..Sending";
    update["passwordCurrent"] = password.querySelector("#password-current").value;
    update["password"] = password.querySelector("#password").value;
    update["confirm"] = password.querySelector("#password-confirm").value;
    await updateData(update, "password");
    send.textContent = sendTxt;
  });
}

if (addQarea) {
  addQuestion(addQarea);
}

(function (window) {
  "use strict";

  function cards5dap() {
    const _5dapObject = {};

    const settings = {
      timeOutSec: 3,
    };

    _5dapObject.getSettings = function () {
      const mySecurityCopy = {};

      for (let i in settings) {
        if (i) {
          mySecurityCopy[i] = settings[i];
        }
      }

      return mySecurityCopy;
    };

    // Change a private property
    _5dapObject.setTimeOutSec = function (sec) {
      settings.timeOutSec = sec;
      return sec;
    };

    _5dapObject.timeout = function (s) {
      return new Promise(function (_, reject) {
        setTimeout(function () {
          reject(new Error(`Request took too long! Timeout after ${s} second`));
        }, s * 1000);
      });
    };

    _5dapObject.showOn = function () {
      if (env().startsWith('http://')) {
        console.log(...arguments);
      }
    };

    _5dapObject.testQuestion = testId => {
      testQuestion(testId);
    };

    _5dapObject.login = (email, password, remember) => {
      sendLogin(email, password, remember);
    }

    _5dapObject.signUp = (nickname, email, password, passwordConfirm) => {
      sendSignUp(nickname, email, password, passwordConfirm);
    }

    _5dapObject.logout = () => {
      sendLogout();
    }

    return _5dapObject;
  }
  
  // We need that our library is globally accesible, then we save in the window
  if (typeof window.cards5dap === "undefined") {
    window.cards5dap = cards5dap();
  }
})(window);
