import { updateData } from "./requests";
import { AJAX } from "./utils.js";
import { showAlert } from "./alert";
import { env } from './config';

import { QuestionImg } from "./canvas";

import { drawLatex, dispLatex } from "./latex";
import { async } from "regenerator-runtime";

const host = env();

// 수식안에 devider 넣은 것 불가
const existDelimiterInEq = (txts, delimiter) => {
  let pattern = "(<eq>.*s*.*" + delimiter + "[a-zA-Z가-힣1-9]+" + delimiter + ".*s*.*</eq>)";
  let regExp = RegExp(pattern, "g");

  return txts.match(regExp) === null ? false : true;
};

const clearArea = eleArea => {
  eleArea.innerHTML = "";
};

const generateText = eleParent => {
  const markUp = `
    <div class='form-group'>
     <textarea id='content' placeholder='질문' class='form__input' rows='5' name='content'></textarea>
    </div>
  `;
  eleParent.insertAdjacentHTML("afterbegin", markUp);
};

const generateOX = eleParent => {
  const markUp = `
  <div class="form__radio-group">
    <input type="radio" class="form__radio-input" name="answer" value="false" id="false">
    <label for="false" class="form__radio-label">
      <span class="form__radio-box"></span>틀리다(false)
    </label>
  </div>
  <div class="form__radio-group">
    <input type="radio" class="form__radio-input" name="answer" value="true" id="true">
    <label for="true" class="form__radio-label">
      <span class="form__radio-box"></span>맞다(true)
    </label>
  </div>`;
  eleParent.insertAdjacentHTML("beforeend", markUp);
};

const generateSingle = (eleParent, wordCnt = undefined) => {
  const ph = wordCnt > 1 ?  `hint: 단어 ${wordCnt} 개(space 구분)` : 'answer';
  const markUp = `
    <input type="text" name="answer" id="answer" placeholder='${ph}' class="form__input" required autocomplete="off">
  `;
  eleParent.insertAdjacentHTML("beforeend", markUp);
};

const generateMulti = (eleParent, wordCnt = 1, devider = undefined) => {
  const value = devider ? `value='${devider}'` : '';
  const ph = wordCnt > 1 ?  `hint: 단어 ${wordCnt} 개 ( ${devider ? devider : '␣'} 구분 )` : 'answer';
  const markUp = `
  <label for="devider">구분기호</label>
  <input type="text" name="devider" id="devider" placeholder="공백" ${value} size='1' />
  <input type="text" name="answer" id="answer" placeholder="${ph}" class="form__input" required autocomplete="off" />  `;
  eleParent.insertAdjacentHTML("beforeend", markUp);
};

const generateList = (eleParent, devider = undefined) => {
  const markUp = `
    <div class="form-group">
      <label for="delimiter">패턴구분자</label>
      <input type="text" class="form-control" id="delimiter" name="delimiter" value=${devider ? devider : '@'} disabled>
    </div>
    <div class="form-group">
      <label for="answer">정답</label>
      <input type="text" class="add__control" id="answer" name="answer" disabled>
    </div>
  `;
  eleParent.insertAdjacentHTML("beforeend", markUp);
};

const maskedByAnswer = (question, answer, elDevider = null) => {
  if (!elDevider) {
    //answer single
    // console.log(strPreview, str);
    let input = `<input type='text' placeholder='1' size='${answer.length}' >`;
    return question.replaceAll(answer, input);
  } else {
    let devider = elDevider.value.length == 1 ? elDevider.value : " ";

    let ansArr = answer.trim().split(devider);
    //길이가 긴 답안부터, 답안끼리 replace 되는 것 방지
    const notNuls = ansArr
      .filter(el => el !== "")
      .map(el => el.trim())
      .sort((a, b) => b.length - a.length);
    //preview render
    return notNuls.reduce((acc, el, idx) => {
      const reps = el.trim();
      let input = `<input type='text' placeholder='${idx + 1}' size='${reps.length}' >`;
      return (acc = acc.replaceAll(reps, input));
    }, question);
  }
};

const extractListAnswer = eleForm => {
  const preview = eleForm.querySelector("#preview");
  const content = eleForm.querySelector("textarea#content");
  const answer = eleForm.querySelector("input#answer");
  const delimiter = eleForm.querySelector("input#delimiter");

  content.addEventListener("input", e => {
    let mark = delimiter.value;
    let str = e.target.value;

    if (existDelimiterInEq(str, mark)) {
      alert("수식안에 패턴구분자를 사용할 수 없습니다.");
      return;
    }
    let pattern = `${mark}[^\\s${mark}]+${mark}`;
    let regExp = RegExp(pattern, "g");

    let array = str.match(regExp);
    if (array) {
      let regExpReplace = RegExp(mark, "g");
      answer.value = array.reduce((acc, el, idx) => {
        if (el.trim().length < 4) {
          alert("정답항목은 최소 2자입니다.");
        } else {
          return (acc += `${idx + 1},${el.replace(regExpReplace, " ").trim()} `);
        }
      }, "");
      //preview render
      preview.innerHTML = array.reduce((acc, el, idx) => {
        let input = `<input type='text' placeholder='${idx + 1}' size='${el.length}' >`;
        return (acc = acc.replace(el, input));
      }, str);
    } else {
      answer.value = "";
    }
  });
};

const previewByAnswer = eleForm => {
  const preview = eleForm.querySelector("#preview");

  const content = eleForm.querySelector("textarea#content");
  const answer = eleForm.querySelector("input#answer");
  const elDevider = eleForm.querySelector("input#devider");

  answer.addEventListener("input", e => {
    let str = e.target.value;
    preview.innerHTML = maskedByAnswer(content.value.replaceAll("\n",'<br />'), str, elDevider);
  });
};

export const addQuestion = eleForm => {
  // const areaParent = eleForm.querySelector("#display.form__question");
  const areaQuestion = eleForm.querySelector("#question.add__question");
  const areaAnswer = eleForm.querySelector("#answer.add__answer");
  const selQtype = eleForm.querySelector("select#qtype");
  const selAtype = eleForm.querySelector("select#atype");
  const newQdata = new FormData();
  const send = eleForm.querySelector("button#add-send");
  const imageUpload = new QuestionImg(areaQuestion);

  selQtype.addEventListener("change", e => {
    //0 ; text, 1; image
    let kind = e.target.value;
    newQdata.set(e.target.name, kind);
    clearArea(areaQuestion);
    switch (kind) {
      case "0":
        clearArea(preview);
        preview.style.display = 'block';
        generateText(areaQuestion);
        // drawLatex("content", "preview");
        break;
      case "1":
        // clearArea(preview);
        preview.style.display = 'none';
        clearArea(addQuestion);
        imageUpload.prepareCanvas();
        break;
      default:
        generateText(areaQuestion);
    }
  });

  selAtype.addEventListener("change", e => {
    let kind = e.target.value;
    newQdata.set(e.target.name, kind);
    clearArea(areaAnswer);
    clearArea(preview);
    switch (kind) {
      case "0":
        generateOX(areaAnswer, newQdata);
        break;
      case "1":
        generateSingle(areaAnswer);
        previewByAnswer(eleForm);
        break;
      case "2":
        generateMulti(areaAnswer);
        previewByAnswer(eleForm);
        break;
      case "3":
        if (selQtype.value == "0") {
          generateList(areaAnswer);
          extractListAnswer(eleForm);
        } else {
          //image
          // clearArea(areaQuestion);
          generateMulti(areaAnswer);
          previewByAnswer(eleForm);
        }
        break;
      default:
        // generateSingle(areaAnswer);
    }
  });
  send.addEventListener("click", async e => {
    e.preventDefault();
    //question
    if (content) newQdata.set(content.name, content.value);
  
    if (selQtype.value === '1') {
      // image routine
      const blob = imageUpload.dataURL2Blob();
      if (!blob) {
        showAlert('error','missing picture!');
        return;
      }
      newQdata.set('photo', blob, 'undefined.jpeg');
    }
    //keyword
    const keyword = eleForm.querySelector("select#catalogue");
    if (keyword) newQdata.set(keyword.name, keyword.value);
    //answer
    let answer;
    if (selAtype.value === "0") {
      //ox
      answer = eleForm.querySelector('input[name="answer"]:checked');
    } else {
      answer = eleForm.querySelector('input[name="answer"]');
    }
    if (!answer || !answer.value) {
      showAlert('error','missing answer!');
      return;
    }
    if (answer) newQdata.set(answer.name, answer.value);
    
    //devider, delimiter
    const delimiter = eleForm.querySelector('input[name="delimiter"]');
    if (delimiter) newQdata.set(delimiter.name, delimiter.value);
    const devider = eleForm.querySelector('input[name="devider"]');
    if (devider) newQdata.set(devider.name, devider.value);

    // for (const pair of newQdata.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`);
    // }

    // updateData(newQdata, "newQuestion");
    try {

      const res = await AJAX("post",`${host}/api/v1/questions/`, newQdata);
    
      if (res.status === "success") {
        showAlert("success", res.data.message);
        if (selQtype.value === '1') {
          removeEle('.toolbox');
          imageUpload.canvas.clear();
          console.log(imageUpload.fileInput.files)
          // removeEle('.add__question');
          // imageUpload.prepareCanvas();
          } else {
          clearArea(preview);
        }
        content.value = '';
        // if (delimiter) delimiter.value='';
        if (devider) devider.value='';
        if (selAtype.value === '0') answer.checked = false;
        else answer.value = '';
      } else {
        showAlert("warn", res.message);
      }
    } catch (err) {
      showAlert("error", err.message);
      cards5dap.showOn(err);
    }

  });
};

const removeEle = tagNclass => {
  const dom = document.querySelector(tagNclass);
  if (dom) dom.parentNode.removeChild(dom);
}

const attachAnswerForm = (Atype, wordCnt = undefined, devider = undefined) => {
  const parentDom = document.querySelector('.card__form');
  clearArea(parentDom);
  
  switch (Atype) {
    case '0':
      generateOX(parentDom);
      break;
    case '1':
      generateSingle(parentDom, wordCnt);
      break;
    case '2':
      generateMulti(parentDom, wordCnt, devider);
      break;
    case '3':
      generateSingle(parentDom, wordCnt);
      break;
    default:
      throw new Error(`Answer type is wrong ${Atype}`);
  }
}

const scoreResult = (aType, ansCnt, sended, replied)  => {
  let devider, markup, ansArr;
  const board = document.querySelector('.card__check-box');
  if (!board) return;

  const sendedBox = board.querySelector('.card__message');
  const repliedBox = board.querySelector('.card__answer');

  sendedBox.textContent = `send : ${sended.get('answer')}`;
  if (replied.result) {
    repliedBox.textContent = 'GOOD';
    board.classList.add('card__picture--1');
  } else {
    let answer = sended.get('answer');
    repliedBox.textContent = replied.answer;
    const eleForm = document.querySelector(".card__side--front");
    const content = eleForm.querySelector(".card__content--text");

    // const markup = viewScore(content.innerHTML, sended);
    let reps, wrong, subs ;
    switch (aType) {
      //answer single
      case '1':
        reps = ''.padEnd(replied.answer.length,'_' );
        wrong = `<span class='tooltip' data-tooltip='${replied.answer}'>${answer}</span>`; 
        markup = content.innerHTML.replaceAll(`(${reps})`,wrong );
        break;
      case '2':
      //   devider = sended.get('devider');
      //   ansArr = answer.trim().split(devider ? devider : ' ');        
        markup = content.innerHTML;
        break;
      case '3':
        ansArr = answer.trim().split(' ');
        const circleNum = 10122;
        wrong = JSON.parse(replied.answer);
        markup = content.innerHTML;
        for ( let i=0 ; i < ansCnt ; i++) {
          let circleStr = String.fromCharCode(circleNum+i);
          let key = (i+1).toString();
          if (wrong.miss[key]) {
            reps = circleStr.padEnd(wrong.miss[key].length+1,'_' );
            subs = `<span class='tooltip' data-tooltip='${wrong.miss[key]}'>${wrong.over[key]}</span>`; 
          } else {
            reps = circleStr.padEnd(ansArr[i].length+1,'_' );
            subs = ` <b>${ansArr[i]}</b> `  
          }
          markup = markup.replaceAll(`(${reps})`,subs );            
        }
        break;
    }
    board.insertAdjacentHTML("afterbegin", markup)
    
  }

}

const drawCardLayout = flds => {
  const testBox = 'section.dialogue';
  removeEle(testBox);

  const imgLink = `
  <div class='card__content--img ${flds.qtype === '1' ? 'card__visible' : 'card__hidden'} '> 
    <img src='${flds.img}' alt="question image" class="card__picture">
  </div>
`;
  const textContent = flds.content.replace(/\n/g, "<br />");

  const markUp = `
  <section class='dialogue'>
    <div class="card">
      <div class="card__side card__side--front">
        <div class="card__heading">
          <div class="card__heading-span rating-holder">
            rating
          </div>
          <a href="#" class="btn btn--white close">&times;</a>
        </div>
        <div class="card__detail">
          <div class='card__content--text'>${textContent}</div>
          ${flds.qtype === '1' ? imgLink : ''}
        </div>
        <div class="card__form">
          answer form
        </div>
        <div class="card__nav card__nav--skip">
          <div class="btn btn--primary" id="score" data-test='${flds.tid}'>send</div>
        </div>
      </div>
      <div class="card__side card__side--back card__side--back-1">
        <div class="card__close">
          <a href="#" class="btn btn--white close">&times;</a>
        </div>
        <div class="card__check-box">
          <p class="card__message">
            정답율
          </p>
          <p class="card__answer">
            &nbsp;
          </p>
        </div>
        <div class="card__nav card__nav--next">
          <button class="btn btn--white" id="review" data-review="${flds.wid}">review</button>
        </div>
      </div>
    </div>
  </section>
    `;
  document.body.insertAdjacentHTML("afterbegin", markUp);

  //close test window
  // 화면갱신이 필요
  document.querySelectorAll('a.close').forEach(el => el.addEventListener('click', e=> {
    e.preventDefault();
    removeEle(testBox);
    location.reload(true);
  }));

  //answer form attach
  attachAnswerForm(flds.atype, flds.ansCnt, flds.devider);

  //send answer
  // 1. rotate card, 2. send 3. view result (with next button)
  document.querySelector('#score.btn').addEventListener('click', async e=> {
    const front = document.querySelector('.card__side--front');
    const back = document.querySelector('.card__side--back');
    e.preventDefault();

    //1. rotate start
    front.style.transform = 'rotateY(-45deg)';
    front.style.webkitTransform = 'rotateY(-45deg)';
    back.style.transform = 'rotateY(135deg)';
    back.style.webkitTransform = 'rotateY(135deg)';

    //2. send
    const answer = new FormData;
    const sending = document.getElementById('answer').value;
    answer.set('answer', sending);
    answer.set('testId', e.target.dataset.test);
    const devider = document.getElementById('devider');
    if (devider) answer.set('devider',devider.value);

    try {

      const res = await AJAX("post", `${host}/api/v1/questions/score`, answer);
    
      if (res.status === "success") {
        scoreResult(flds.atype,flds.ansCnt, answer, res.data);
      } else {
        showAlert("warn", res.message);
      }
    } catch (err) {
      showAlert("error", err.message);
    }
  
      //3. view result
      front.style.transform = 'rotateY(-180deg)';
      front.style.webkitTransform = 'rotateY(-180deg)';
      back.style.webkitTransform = 'rotateY(0deg)';

  });

}

const attachNext = cbFn => {
  const skipMarkup ='<button class="btn btn--white next"">skip</button>';
  const skipNav = document.querySelector('.card__nav--skip');
  skipNav.insertAdjacentHTML("beforeend", skipMarkup)
  
  const nextMarkup ='<button class="btn btn--white next"">next</button>';
  const nextNav = document.querySelector('.card__nav--next');
  nextNav.insertAdjacentHTML("beforeend", nextMarkup)
  
  const btnNexts = document.querySelectorAll('button.next');
  
  if (btnNexts) btnNexts.forEach(el => el.addEventListener('click', e=> {
    cbFn();
  }));
}

const attachReview = () => {
  const btnReview = document.querySelector('button#review');
  if (btnReview) {
    btnReview.addEventListener('click', ev => {
      const reviewId = ev.target.dataset.review;
      window.location.replace(`/review/${reviewId}`);
    })
  }
}

export const testQuestion = async tests => {
  // const tests = remains;
  let first, cbFn;

  if (tests.length < 1) return;
    first = tests.shift();
    cbFn = () => testQuestion(tests);
    try {
      
      const res = await AJAX("get", `${host}/api/v1/questions/test/${first}`);
      // console.log(res.data);
      
      if (res.status === "success") {
        drawCardLayout(res.data);
        attachReview();
        if (tests.length > 0) attachNext(cbFn);
      } else {
        showAlert("error", res.message);
      }
    } catch (err) {
    // console.log(err);
    showAlert("error", err.message);
  }

};
