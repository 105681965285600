import { async } from "regenerator-runtime";

// const timeout = function (sec) {
//   return new Promise(function (_, reject) {
//     setTimeout(function () {
//       reject(new Error(`Request took too long! Timeout after ${sec} second`));
//     }, sec * 1000);
//   });
// };

export const AJAX = async (protocol, url, upload = undefined) => {

  let fetchPro, contentType, sendData;
  if (upload !== undefined) {
    // console.log(upload instanceof FormData);
    if (upload instanceof FormData) {
      // contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
      contentType = "multipart/form-data ; charset=UTF-8";
      sendData = upload;
    } else {
      contentType = "application/json; charset=UTF-8";
      sendData = JSON.stringify(upload);
    }
  }
  // const secureHeaders = new Headers();
  // secureHeaders.append("Content-Type", "application/json; charset=UTF-8");
  // secureHeaders.append("X-Secure-Key", "123456");

  try {
    switch (protocol.toUpperCase()) {
      case "POST":
        fetchPro = fetch(url, {
          method: "POST",
          // headers: { "content-Type": contentType },
          body: sendData,
        });
        break;
      case "PUT":
        fetchPro = fetch(url, {
          method: "PUT",
          headers: { "content-Type": contentType },
          body: sendData,
        });
        break;
      case "PATCH":
        fetchPro = fetch(url, {
          method: "PATCH",
          //formdata로 보낼때는 제외한다.
          // headers: { 'content-Type': contentType },
          body: sendData,
        });
        break;
      case "DELETE":
        fetchPro = fetch(url, { method: "DELETE" });
        break;
      case "GET":
         fetchPro = fetch(url, { method: "GET" });
         break;
      // default:
      //   fetchPro = fetch(url);
    }

    const res = await Promise.race([fetchPro, cards5dap.timeout(3)]);
    cards5dap.showOn(res);
    const data = await res.json();
    cards5dap.showOn(data);

    // if (!res.ok) throw new Error(data.status, data.message);
    if (!res.ok) throw data;

    return data;
  } catch (err) {
    // console.log(err);
    throw err;
  }
};

const readURL = input => {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $('#photo').attr('src', e.target.result);
    }

    reader.readAsDataURL(input.files[0]);
  }
}