const strip_tags = str => str.replace(/(<([^>]+)>)/gi, "");

const hideAlert = () => {
  const alertBox = document.querySelector(".alert");
  if (alertBox) alertBox.parentNode.removeChild(alertBox);
};

//에러메시지에 html tag가 포함된 경우 참고정보를 link로 보내주는 경우
export const showAlert = (kind, message) => {
  hideAlert();

  const hostErrMsg = message !== strip_tags(message);
  const waitmsg = `<span class="closebtn" onclick="hideAlert()">&times;</span>`;
  const boxmsg = hostErrMsg ? waitmsg + message : message;
  // const boxmsg = waitmsg + message;

  const alertBox = `<div class='alert alert--${kind}'>${boxmsg}</div>`;
  document.body.insertAdjacentHTML("afterbegin", alertBox);
  
  if (!hostErrMsg) {
    window.setTimeout(() => {
      hideAlert();
    }, 5000);
  }
};
