import { showAlert } from "./alert.js";
import { AJAX } from "./utils.js";
import { env } from './config';

const host = env();


export const sendSignUp = async (nickname, email, password, confirm) => {
  try {
    const upload = {
      nickname,
      email,
      password,
      confirm,
    };

    const res = await AJAX("post", `${host}/api/v1/auth/signup`, upload);
    // console.log(res);

    if (res.status === "success") {
      showAlert("success", res.data.message);
      window.setTimeout(() => {
        // location.assign("/");
        window.history.back();
      }, 3000);
    } else {
      showAlert("error", res.message);
    }
  } catch (err) {
    // console.log(err);
    showAlert("error", err.message);
  }
};

export const sendLogin = async (email, password, remember) => {
  try {
    const upload = {
      email,
      password,
      remember,
    };

    const res = await AJAX("post", `${host}/api/v1/auth/login`, upload);
    // console.log(res);

    if (res.status === "success") {
      showAlert("info", res.data.message);
      window.setTimeout(() => {
        location.assign("/todo#today");
      }, 3000);
    } else {
      showAlert("error", res.message);
    }
  } catch (err) {
    // console.log(err);
    showAlert("error", err.message);
  }
};

export const sendLogout = async () => {
  try {
    const res = await AJAX("get", `${host}/api/v1/auth/logout`);
    // console.log(res, "logout");

    if (res.status === "success") {
      showAlert("info", res.data.message);
      window.setTimeout(() => {
        location.assign('/');
      }, 3000);
    } else {
      showAlert("warn", res.message);
    }
  } catch (err) {
    // console.log(err);
    showAlert("error", "Error on logging out! Try Again!");
  }
};

export const updateData = async (update, type) => {
  try {
    let method, url;
    switch (type) {
      case "password":
        method = "patch";
        url = `${host}/api/v1/auth/updatepassword`;
        break;
      case "user":
        method = "post";
        url = `${host}/api/v1/users/updateInfo`;
        break;
      case "newCatalogue":
        method = "post";
        url = `${host}/api/v1/sets/`;
        break;
      // case "newQuestion":
      //   method = "post";
      //   url = `${host}/api/v1/questions/`;
      //   break;
      default:
        return showAlert("error", "invalid request Url.");
    }
    const res = await AJAX(method, url, update);
    // console.log(res);

    if (res.status === "success") {
      showAlert("success", res.data.message);
      // window.setTimeout(() => {
      //   location.reload();
      // }, 1500);
    } else {
      showAlert("warn", res.message);
    }
  } catch (err) {
    console.log(err);
    showAlert("error", err.message);
  }
};
